* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:black ;
}
.app {
    text-align: center;
    /* padding-top: 30px; */
    font-size: 40px;
    margin-top: 50px;
    color: white;
 
    

}
/* .inputbox{
    max-width: 600px;
    width: 90%;
    height: 300px;
    background-color: aqua;
    margin: 0 28px;
    border: 7px solid rgb(0, 0,0,.5);
} */
    

.btn{
    display: flex;
    gap: 1rem;
    margin-bottom: 20px;
    padding: 10px 20px;
    & >*{
      border: none;
      background-color: rgb(114, 111, 111);
      box-shadow: 0px 0px 10px #cfd8dc;
      font-size: 2rem;
      color: inherit;
      border-radius: 50%;
      height: 5rem;
      width: 5rem;
      cursor: pointer;
      outline: none;
      transition: background-color 250ms ease-in-out transistion 50ms ease-in-out;
      &:hover{
        background-color: #e4ecef;
      }
      &:active{
        transform: scale(0.9);
      }
    }
}

/* .btn button {
    background-color: bisque;
    color: black;
    font-size: 20px;
    border: 4px solid rgb(0, 0, 0,.5);
    border-radius: 10px;
    box-sizing: border-box;
    font-weight: 500;
    width: 30%;
    padding: 10px 20px;
    cursor: pointer;
    gap: 10rem; */
    
/* } */
.negative{
    color: #cc0f35;
    animation: plus 500ms ease-in-out;
}
.positive{
    color: #23c074;
    animation: plus 500ms ease-in-out;
}
@keyframes plues{
    0%,100%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.2);
    }
}